import React, { createContext, useCallback, useMemo, useState } from "react";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";

export const CompanyProfileContext = createContext();

export const CompanyProfileContextProvider = ({
  clientMnemonic,
  disabledDate,
  children,
}) => {
  const [success, setSuccess] = useState(null);
  const [response, setResponse] = useState("");
  const apiCallback = useCallback((apiSuccess, result) => {
    setSuccess(apiSuccess);
    if (apiSuccess) {
      if (result?.response?.data) {
        setResponse(JSON.stringify(result.response.data));
      } else {
        setResponse("Saved Successfully");
      }
    } else {
      setResponse(JSON.stringify(result.response.data));
    }
  }, []);
  const value = useMemo(
    () => ({
      clientMnemonic,
      disabledDate,
      apiCallback,
    }),
    [clientMnemonic, disabledDate, apiCallback],
  );
  return (
    <CompanyProfileContext.Provider value={value}>
      {children}
      <Snackbar
        open={success !== null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSuccess(null)}
          severity={success ? "success" : "error"}
        >
          {response}
        </Alert>
      </Snackbar>
    </CompanyProfileContext.Provider>
  );
};
