import React, { useEffect, useState } from "react";

import { Box, Button, Card, Divider, useTheme } from "@mui/material";
import Alert from "@mui/material/Alert";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

import { API } from "../../../../utils/api";
import ResourcesInputGroup from "../ResourcesInputGroup";
import DiscoveryBundles from "./DiscoveryBundles";

const useStyles = makeStyles(() => ({
  root: {
    padding: "3em",
  },
}));

export default function Resources({ className, userData, resources, misightUserId, fetchMisightUserData }) {
  const classes = useStyles();
  const theme = useTheme();

  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  // Separate state so that the user can cancel an edit
  const [editableResources, setEditableResources] = useState([]);

  const nothingChanged = editableResources === resources;
  const emptyResource = editableResources?.some(resource => !resource.resource_value);
  const missingStudy = editableResources?.some(resource => !resource.study);
  const missingDefault = editableResources.length > 0 && editableResources?.every(resource => !resource.is_default);

  useEffect(() => {
    if (resources?.length) {
      setEditableResources(resources);
    }
  }, [resources]);

  function handleSave() {
    setSaving(true);
    setSuccess("");
    setError("");
    const requests = [];
    // Check for deleted resources
    resources.forEach((resource) => {
      if (!editableResources.find(r => r.id === resource.id)) {
        requests.push(API.request(
          { url: `misight/users/${misightUserId}/resources/${resource.id}`, method: "DELETE", data: resource },
        ).then(
          () => setSuccess(prevMsg => prevMsg.concat(`-- Resource ${resource.resource_value} Successfully Deleted! --`)),
          (e) => setError(prevMsg => prevMsg.concat(`-- Failed to delete ${resource.resource_value} as a resource: ${e.response.data} --`)),
        ));
      }
    });
    if (editableResources.length && !nothingChanged) {
      editableResources.forEach((resource) => {
        // New resource
        if (!resource.id) {
          requests.push(API.request(
            { url: `misight/users/${misightUserId}/resources`, method: "POST", data: resource },
          ).then(
            () => setSuccess(prevMsg => prevMsg.concat(`-- Resource ${resource.resource_value} Successfully added! --`)),
            (e) => setError(prevMsg => prevMsg.concat(`-- Failed to add ${resource.resource_value} as a resource: ${e.response.data} --`)),
          ));
        } else {
          // Update resource
          requests.push(API.request(
            { url: `misight/users/${misightUserId}/resources/${resource.id}`, method: "POST", data: resource },
          ).then(
            () => setSuccess(prevMsg => prevMsg.concat(`-- Resource ${resource.resource_value} Successfully updated! --`)),
            (e) => setError(prevMsg => prevMsg.concat(`-- Failed to update ${resource.resource_value} as a resource: ${e.response.data} --`)),
          ));
        }
      });
    }
    Promise.all(requests)
      .then(() => {
        setSuccess(prevMsg => prevMsg.concat("-- Update complete --"));
        setSaving(false);
        fetchMisightUserData();
      })
      .catch((e) => {
        setError(e?.response?.data);
        setSaving(false);
        fetchMisightUserData();
      });
  }

  return (
    <Card className={clsx(classes.root, className)}>
      {!userData?.client && <div><Alert severity="info" style={{ ...theme.common.alerts, marginBlock: 10 }}>Client is required to add resources</Alert></div>}
      <ResourcesInputGroup
        clientId={userData?.client}
        resources={editing ? editableResources : (resources || [])}
        setResources={setEditableResources}
        disabled={!editing}
        userData={userData}
      />
      <Divider style={{ marginBlock: 30 }} />
      {saving && <div><Alert severity="info" style={theme.common.alerts}>Updating profile...</Alert></div>}
      {success && <div><Alert severity="success" style={theme.common.alerts}>{`${success}`}</Alert></div>}
      {error && <div><Alert severity="error" style={theme.common.alerts}>{`${error}`}</Alert></div>}
      {emptyResource && <div><Alert severity="error" style={theme.common.alerts}>A resource is missing a value</Alert></div>}
      {missingStudy && <div><Alert severity="error" style={theme.common.alerts}>A resource is missing a study</Alert></div>}
      {missingDefault && <div><Alert severity="error" style={theme.common.alerts}>No resource is set as the default</Alert></div>}
      {!misightUserId && <div><Alert severity="error" style={theme.common.alerts}>No Misight user found for this Okta user. Cannot edit Misight user resources</Alert></div>}
      <Box
        display="flex"
        justifyContent="flex-end"
        style={{ marginBlock: 30 }}
      >
        {editing
          ? (
            <>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSave}
                style={theme.common.confirmButton}
                disabled={saving || nothingChanged || emptyResource || missingStudy || missingDefault}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  setEditing(false);
                  setSuccess("");
                  setError("");
                }}
                disabled={saving}
              >
                Cancel
              </Button>
            </>
          )
          : (
            <Button
              color="primary"
              variant="contained"
              onClick={() => setEditing(!editing)}
              disabled={!misightUserId}
            >
              Edit Resources
            </Button>
          )}
      </Box>
      <DiscoveryBundles userData={userData} />
    </Card>
  );
}
