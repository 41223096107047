import React from "react";

import {
  Button,
  Divider,
  useTheme,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";

export default function DiscoveryBundles({ userData }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const onClickProfile = () => {
    navigate(`/company-profile/details/${userData.client}`);
  };

  const onClickBundles = () => {
    navigate("/api-access-control", {
      state: { selectedTab: "clients", client: userData.client },
    });
  };

  return (
    <>
      {!userData?.client && (
        <div>
          <Alert
            severity="info"
            style={{ ...theme.common.alerts, marginBlock: 10 }}
          >
            Client is required to view bundles
          </Alert>
        </div>
      )}
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Discovery Bundles
        </Typography>
        <Divider style={{ marginBottom: 20 }} />
      </Grid>
      {userData?.client && (
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Button variant="outlined" color="primary" onClick={onClickProfile}>
              <Tooltip title="Company Profile Page" arrow>
                {`${userData.client}  Profile`}
              </Tooltip>
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button variant="outlined" color="primary" onClick={onClickBundles}>
              <Tooltip title="Bundles Assignment Page" arrow>
                {`${userData.client}  Bundles`}
              </Tooltip>
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}
