import { React, useMemo } from "react";
import { FormControl, Grid, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { roles } from "../Roles";

export default function UserForm({ userData, setUserData, disabled, isAPIUser = false }) {
  const formOptions = useSelector((state) => state.formOptions);

  const handleChange = (event) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  };

  const handleClientChange = (e, v) => {
    if (v === null) {
      setUserData({ ...userData, client: "" });
    } else {
      setUserData({ ...userData, client: v });
    }
  };

  const filterOptions = createFilterOptions({
    ignoreCase: true,
  });

  const clients = useMemo(() => {
    let combinedOptions = ["DEMO", "ISS"];
    if (formOptions.clients) {
      combinedOptions = formOptions.clients.map(c => c.id).concat(combinedOptions);
    }
    if (formOptions.lms_backend_clients) {
      combinedOptions = formOptions.lms_backend_clients.map(c => c.mnemonic).concat(combinedOptions);
    }
    return [...new Set(combinedOptions)];
  }, [formOptions.clients, formOptions.lms_backend_clients]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label="First name"
            name="first_name"
            onChange={handleChange}
            required
            value={userData?.first_name ?? ""}
            variant="outlined"
            disabled={disabled}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label="Last name"
            name="last_name"
            onChange={handleChange}
            required
            value={userData?.last_name ?? ""}
            variant="outlined"
            disabled={disabled || isAPIUser}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label="Email Address"
            name="email"
            onChange={handleChange}
            required
            value={userData?.email ?? ""}
            variant="outlined"
            disabled={disabled}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl required fullWidth variant="outlined">
            <Autocomplete
              id="role-input"
              freeSolo
              disabled={disabled || isAPIUser}
              value={userData?.role ?? ""}
              options={isAPIUser ? ["API"] : roles.map((option) => option.value)}
              onChange={(e, v) => {
                setUserData({ ...userData, role: v });
              }}
              onInputChange={(e, v) => {
                setUserData({ ...userData, role: v });
              }}
              renderInput={(params) => <TextField {...params} label="Role" />}
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label="Title"
            name="title"
            onChange={handleChange}
            value={userData?.title ?? ""}
            variant="outlined"
            disabled={disabled}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl required fullWidth variant="outlined">
            <Autocomplete
              id="client-input"
              freeSolo={isAPIUser}
              disabled={disabled}
              value={userData?.client ?? ""}
              options={clients}
              onChange={handleClientChange}
              renderInput={(params) => <TextField {...params} label="Client" variant="outlined" />}
              filterOptions={filterOptions}
            />
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}
