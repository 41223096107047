import React from "react";
import { Box, Grid } from "@mui/material";
import usePageTitle from "../../hooks/usePageTitle";
import Companies from "./Companies";

export default function DiscoveryData() {
  usePageTitle("Companies");

  return (
    <Grid container spacing={2}>
      <Box style={{ width: "100%", marginLeft: 10 }}>
        <Companies />
      </Box>
    </Grid>
  );
}
