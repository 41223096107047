import React, { useEffect, useState, useMemo } from "react";
import {
  ContactMail as ClientProfileIcon,
  Business as BusinessIcon,
  Check as CheckIcon,
  ChevronLeft as ChevronLeftIcon,
  Group as GroupIcon,
  Menu as MenuIcon,
  ListAlt as StatusTableIcon,
  PersonPinCircle as PersonPinCircleIcon,
  VpnKey as VpnKeyIcon,
  Speed as SpeedIcon,
  Storage as StorageIcon,
  StarOutlined as StarOutlinedIcon,
  AddCircle as AddTaskIcon,
  Fireplace as PrewarmIcon,
  Settings as SettingsIcon,
  GroupAdd as SuperAdminIcon,
} from "@mui/icons-material";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import {
  Box,
  Button,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Toolbar,
  Tooltip,
  Typography,
  AppBar as MuiAppBar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import CssBaseline from "@mui/material/CssBaseline";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

import { fetchAuthSession } from "aws-amplify/auth";
import { useSelector } from "react-redux";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import { GraphQlIcon, DatabaseSyncIcon, AccessControlIcon } from "../components/icons";
import usePrivilege from "../hooks/usePrivilege";
import PageNotFound from "./404";
import ApiAccessControl from "./MISight/access_control";
import MisightAccounts from "./MISight/accounts";
import AccountView from "./MISight/accounts/AccountView";
import UsersImport from "./MISight/accounts/Import/index";
import MisightData from "./MISight/data/MisightData";
import GraphiQL from "./MISight/graphi";
import Performance from "./MISight/performance";
import Prewarm from "./MISight/prewarm";
import VersionsInfo from "./VersionsInfo";
import EditWorkflow from "./analysts_workflow/EditWorkflow";
import WorkflowDetails from "./analysts_workflow/WorkflowDetails";
import LmsClients from "./clients/profiles";
import ClientProfileDetails from "./clients/profiles/ProfileDetails";
import MisightClientProfileGroupingConfig from "./clients/profiles/athena";
import LmsDbRefreshStatus from "./dbrefresh";
import DiscoveryData from "./discovery_data";
import CompanyProfileDetails from "./discovery_data/CompanyProfileDetailes";
import { DisplayClientSpecificMapping } from "./preprocessing_transforms/DisplayClientSpecificMapping";
import { DisplayCommonHeaderMapping } from "./preprocessing_transforms/DisplayCommonHeaderMapping";
import { DisplayCommonTransforms } from "./preprocessing_transforms/DisplayCommonTransforms";
import SettingsPage from "./settings";
import SplitBrokers from "./split_brokers";
import TaskDetails from "./tasks/TaskDetails";
import ClientConfiguration from "./tasks/client_configuration";
import TaskCreateWizard from "./tasks/create_wizard/index";
import TabSelectorView from "./tasks/status";
import ReportTemplates from "./tasks/templates";
import UserGroups from "./user_groups";

const drawerWidth = 240;
const deploymentEnv = process.env.REACT_APP_CUSTOM_NODE_ENV;

const envColors = Object.freeze({
  local: "white",
  dev: "#debe52",
  stage: "#736dbf",
  prod: "#d85f5f",
});

const envDisplayName = Object.freeze({
  local: "Local",
  dev: "Development",
  stage: "Staging",
  prod: "Production",
});

const useStyles = makeStyles((theme) => ({
  appName: {
    fontWeight: 300,
    fontSize: "1.5em",
  },
  appNameColor: {
    fontSize: "1.5em",
    color: theme.palette.text.link,
  },
  deploymentEnv: {
    color: envColors[deploymentEnv],
    textTransform: "capitalize",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }),
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MainView() {
  const classes = useStyles();
  const pageTitle = useSelector(state => state.application.pageTitle);
  const backendType = useSelector(state => state.application.backendType);

  const cognitoGroups = useSelector(state => state.application.cognitoGroups);
  const groups = useSelector((state) => state.userSettings.groups);

  const [submitMessage, setSubmitMessage] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  // Refresh Create Task Component on clicking Plus Button
  const [copiedJWT, setCopiedJWT] = useState(false);

  const updateSubmitMessage = (message, sever = "info") => {
    setSubmitMessage(message);
    setSeverity(sever);
  };

  usePrivilege();

  useEffect(() => {
    document.title = `Braavos ${backendType} | ${pageTitle}`;
  }, [backendType, pageTitle]);

  const drawerItems = [
    { label: "Status Table", to: "/status", icon: <StatusTableIcon />, backendType: "ALL", group: ["base"] },
    { label: "Create Task / Workflow", to: "/create", icon: <AddTaskIcon />, backendType: "ALL", group: ["base"] },
    { label: "Client Profiles", to: "/client-profiles", icon: <ClientProfileIcon />, backendType: "LMS", group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { label: "Misight Client Config", to: "/misight-client-config", icon: <PersonPinCircleIcon />, backendType: "LMS", group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { label: "Split Brokers", to: "/split-brokers", icon: <BusinessIcon />, backendType: "LMS", group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { label: "Report Templates", to: "/report-templates", icon: <StarOutlinedIcon />, backendType: "LMS", group: ["super-admin"] },
    { label: "Discovery Data", to: "/discovery-data", icon: <LocationCityIcon />, backendType: "LMS", group: ["super-admin"] },
    { label: "MISight Accounts", to: "/accounts", icon: <GroupIcon />, backendType: "LMS", group: ["super-admin", "data-ops-management", "client-success"] },
    { label: "LMS MISight Data", to: "/lms-data", icon: <StorageIcon />, backendType: "LMS", group: ["super-admin", "data-ops-management", "client-success"] },
    { label: "API Access Control", to: "/api-access-control", icon: <AccessControlIcon />, backendType: "LMS", group: ["super-admin", "client-success"] },
    { label: "User Groups Management", to: "/user-groups", icon: <SuperAdminIcon />, backendType: "LMS", group: ["super-admin"] },
    { label: "Data Refresh Control", to: "/db-refresh", icon: <DatabaseSyncIcon />, backendType: "LMS", group: ["super-admin", "data-ops-management"] },
    { label: "GraphQL", to: "/graphql", icon: <GraphQlIcon />, backendType: "LMS", group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { label: "Pre Warm", to: "/pre-warm", icon: <PrewarmIcon />, backendType: "LMS", group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { label: "Performance", to: "/performance", icon: <SpeedIcon />, backendType: "LMS", group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { label: "Settings", to: "/settings", backendType: "LMS", icon: <SettingsIcon />, group: ["base"] },
    // Filter for groups that the user subscribes to
  ].filter(item => (item.group.some(group => groups.includes(group)) || item.group.includes("base")) && (item.backendType === backendType || item.backendType === "ALL"));

  const contentRoutes = [
    { path: "/", element: <Navigate replace to={{ pathname: "/status" }} />, group: ["base"] },
    { path: "/status", element: <TabSelectorView />, group: ["base"] },
    { path: "/create", element: <TaskCreateWizard setSubmitMessage={updateSubmitMessage} />, group: ["base"] },
    { path: "/versions", element: <VersionsInfo />, group: ["base"] },
    { path: "/edit/:taskIdParam", element: <TaskCreateWizard setSubmitMessage={updateSubmitMessage} />, group: ["base"] },
    { path: "/edit/:workflowIdParam/:taskIdParam", element: <TaskCreateWizard setSubmitMessage={updateSubmitMessage} />, group: ["base"] },
    { path: "/details/:taskIdParam", element: <TaskDetails />, group: ["base"] },
    { path: "/details/:workflowIdParam/:taskIdParam", element: <TaskDetails />, group: ["base"] },
    { path: "/discovery-data", element: <DiscoveryData />, group: ["super-admin"] },
    { path: "/company-profile/details/:clientMnemonic", element: <CompanyProfileDetails />, group: ["super-admin"] },
    { path: "/workflows/details/:workflowNameParam", element: <WorkflowDetails />, group: ["base"] },
    { path: "/client-configuration", element: <ClientConfiguration />, group: ["base"] },
    { path: "/client-profiles", element: <LmsClients />, group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { path: "/preprocessing-transform/common-transform/studyFileType/:studyFileType/id/:id", element: <DisplayCommonTransforms />, group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { path: "/preprocessing-transform/common-header-mapping/studyFileType/:studyFileType/id/:id", element: <DisplayCommonHeaderMapping />, group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { path: "/preprocessing-transform/client-specific-transform/client/:client/studyFileType/:studyFileType/id/:id", element: <DisplayClientSpecificMapping />, group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { path: "/client-profile/details/:profileId/study/:study", element: <ClientProfileDetails />, group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { path: "/misight-client-config", element: <MisightClientProfileGroupingConfig drawerOpen={drawerOpen} />, group: ["super-admin", "data-ops-management", "client-success"] },
    { path: "/split-brokers", element: <SplitBrokers />, group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { path: "/report-templates", element: <ReportTemplates />, group: ["super-admin"] },
    { path: "/accounts", element: <MisightAccounts />, group: ["super-admin", "data-ops-management", "client-success"] },
    { path: "/lms-data", element: <MisightData />, group: ["super-admin", "data-ops-management", "client-success"] },
    { path: "/db-refresh", element: <LmsDbRefreshStatus />, group: ["super-admin", "data-ops-management"] },
    { path: "/user/:userId", element: <AccountView />, group: ["super-admin", "data-ops-management", "client-success"] },
    { path: "/importUsers", element: <UsersImport />, group: ["super-admin", "data-ops-management", "client-success"] },
    { path: "/pre-warm", element: <Prewarm />, group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { path: "/performance", element: <Performance />, group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { path: "/api-access-control", element: <ApiAccessControl />, group: ["super-admin", "client-success"] },
    { path: "/graphql", element: <GraphiQL />, group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { path: "/workflows/edit/:workflowNameParam", element: <EditWorkflow setSubmitMessage={updateSubmitMessage} />, group: ["base"] },
    { path: "/settings", element: <SettingsPage />, group: ["base"] },
    { path: "/user-groups", element: <UserGroups />, group: ["super-admin"] },
    { path: "*", element: <PageNotFound />, group: ["base"] },
  ].filter(item => item.group.some(group => cognitoGroups.includes(group)) || item.group.includes("base"));

  function filteredToolbarItems(items) {
    return (
      items?.length > 0 && (
        <>
          <Divider />
          <List>
            {items?.map(({ label, to, icon, otherProps = {} }) => (
              <ListItem
                button
                key={label}
                component={Link}
                to={to}
                {...otherProps}
                style={{ height: 80 }}
              >
                <ListItemIcon>
                  {icon}
                </ListItemIcon>
                <ListItemText primary={label} />
              </ListItem>
            ))}
          </List>
        </>
      )
    );
  }

  const drawerItemsComponent = useMemo(() => (
    filteredToolbarItems(drawerItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [groups, drawerItems]);

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <CssBaseline />
      {/* --- TOP BAR --- */}
      <AppBar
        position="fixed"
        open={drawerOpen}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => { setDrawerOpen(!drawerOpen); }}
            edge="start"
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            <b className={classes.appNameColor}>B</b>
            <b className={classes.appName}>{`raavos ${backendType}`}</b>
            <b className={classes.appName}>{` | ${pageTitle}`}</b>
          </Typography>
          <Box style={{ flexGrow: 1 }} />
          <Button href="/versions" color="secondary">Versions</Button>
          <Tooltip title={copiedJWT ? "Copied!" : "Copy JSON Web Token"}>
            <IconButton
              className={classes.appNameColor}
              onClick={() => {
                fetchAuthSession().then((response) => {
                  if (response && response.tokens.idToken) {
                    navigator.clipboard.writeText(response.tokens.idToken.toString());
                    setCopiedJWT(true);
                    setTimeout(() => setCopiedJWT(false), 3000);
                  }
                });
              }}
              size="large"
            >
              {copiedJWT ? <CheckIcon /> : <VpnKeyIcon />}
            </IconButton>
          </Tooltip>
          <Typography>
            <b className={classes.appName}>{" | "}</b>
            <b className={`${classes.appNameColor} ${classes.deploymentEnv}`}>{`${envDisplayName[deploymentEnv]}`}</b>
          </Typography>
        </Toolbar>
      </AppBar>
      {/*  --- SIDE DRAWER ---  */}
      <Drawer variant="permanent" open={drawerOpen}>
        <DrawerHeader>
          <IconButton onClick={() => { setDrawerOpen(!drawerOpen); }} size="large">
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <div>
          {drawerItemsComponent}
        </div>
      </Drawer>
      {/* ---CONTENT ---  */}
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: `calc(100% - ${drawerWidth}px)` }}>
        <Toolbar />
        <Routes>
          {contentRoutes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Routes>
      </Box>
      {/* MAIN FEEDBACK MESSAGES */}
      <Snackbar
        open={Boolean(submitMessage && submitMessage.length > 0)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <div>
          <Alert onClose={() => setSubmitMessage("")} severity={severity}>
            {submitMessage}
          </Alert>
        </div>
      </Snackbar>
    </Box>
  );
}
