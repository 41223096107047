/* eslint-disable react/no-unstable-nested-components */

import React, { useEffect, useState } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Card, CardContent, InputAdornment, TextField, useTheme, Box } from "@mui/material";

// For fuzzy matching
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
// eslint-disable-next-line import/no-unresolved
import { mkConfig, generateCsv, download } from "export-to-csv";
import { matchSorter } from "match-sorter";
// For highlighting search
import moment from "moment-timezone";
import { nanoid } from "nanoid";
import { Link } from "react-router-dom";

import CircularProgress from "../../../../components/Spinner";
import MaterialTable from "../../../../components/Table";
import UserStatusChip from "../UserStatusChip";
import ActivationDialog from "./ActivationDialog";

function getUniqueValues(data, key) {
  const uniqueValues = [...new Set(data?.map(item => item[key]))]?.sort();
  const lookups = {};
  uniqueValues.forEach(item => {
    lookups[item] = item;
  });
  return lookups;
}

const handleExportData = (csvConfig, data) => {
  const csv = generateCsv(csvConfig)(data);
  download(csvConfig)(csv);
};

const csvConfig = mkConfig({
  useKeysAsHeaders: true,
});

export default function UsersTable({ users, refreshUsers, loading, title }) {
  const theme = useTheme();

  // Search value
  const [searchInput, setSearchInput] = useState("");

  // Filtered down list based on search input
  const [filteredUsersList, setFilteredUsersList] = useState([]);

  // Users Table state
  const [selectedRows, setSelectedRows] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  // --- Filtering and Pagination
  useEffect(() => {
    if (users?.length) {
      const filteredList = matchSorter(users, searchInput, { keys: ["name", "email"] });
      setFilteredUsersList(filteredList);
    }
  }, [users, searchInput]);

  const columns = [
    {
      header: "Name",
      accessorKey: "name",
      filtering: false,
      Cell: ({ row }) => (
        <Button variant="outlined" component={Link} target="_blank" to={`/user/${row.original.id}`}>
          {
            parse(row.original.name, match(row.original.name, searchInput)).map((part) => (
              <span key={nanoid()} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                {part.text}
              </span>
            ))
          }
        </Button>
      ),
    },
    {
      header: "Email",
      accessorKey: "email",
      filtering: false,
      render: rowData => (
        <p>
          {parse(rowData.email, match(rowData.email, searchInput)).map((part) => (
            <span key={nanoid()} style={{ fontWeight: part.highlight ? 700 : 400 }}>
              {part.text}
            </span>
          ))}
        </p>
      ),
    },
    { header: "Client", accessorKey: "client", lookup: getUniqueValues(users, "client") },
    { header: "Role", accessorKey: "role", lookup: getUniqueValues(users, "role") },
    { header: "Title", accessorKey: "title", lookup: getUniqueValues(users, "title") },
    { header: "Okta Status", accessorKey: "status", lookup: getUniqueValues(users, "status"), render: rowData => <UserStatusChip status={rowData.status} /> },
    { header: "Last Login", accessorKey: "last_login", filtering: false, render: rowData => <p>{rowData.last_login ? moment(rowData.last_login).format("MMMM Do YYYY, h:mm:ss a") : <span style={{ color: theme.palette.error.main }}>Never logged in</span>}</p> },
  ];

  const searchLabel = users?.length ? `Search Users: ${users?.length} Available` : "Search Users: No Users Available";

  return (
    <>
      <Card style={{ marginBlock: 10 }}>
        <CardContent>
          <TextField
            id="user-search"
            label={searchLabel}
            type="search"
            variant="outlined"
            onChange={(e) => setSearchInput(e.target.value)}
            style={{ width: "100%", height: "50" }}
            InputProps={{
              startAdornment: <InputAdornment position="start">{loading ? <CircularProgress color="inherit" size={20} /> : <SearchIcon />}</InputAdornment>,
            }}
            autoFocus={!loading}
          />
        </CardContent>
      </Card>
      <MaterialTable
        style={{ marginTop: "1.5em" }}
        title={title}
        columns={columns}
        data={filteredUsersList.map(rowData => ({
          ...rowData,
          tableData: { ...rowData.tableData, disabled: rowData.status === "ACTIVE" },
        })) ?? []}
        isLoading={loading}
        enableRowSelection={(row) => !row.original?.tableData?.disabled}
        actions={[
          { icon: "add",
            onClick: (e, data) => {
              setDialogOpen(true);
              setSelectedRows(data);
            } },
        ]}
        components={{
          Action:
            ({ action, data }) => {
              // This seems to be the only way to override the icon to a button unfortunately
              if (action.icon === "add") {
                return (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={(event) => action.onClick(event, data)}
                  >
                    Activate Users
                  </Button>
                );
              }
              return action.icon;
            },
        }}
        renderTopToolbarCustomActions={() => (
          <Box
            sx={{
              display: "flex",
              gap: "16px",
              padding: "8px",
              flexWrap: "wrap",
            }}
          >
            <Button
              onClick={() => { console.log(filteredUsersList); handleExportData(csvConfig, filteredUsersList.map(user => ({ ...user, groups: `${user.groups}` }))); }}
              startIcon={<FileDownloadIcon />}
            >
              Export All Data
            </Button>
          </Box>
        )}
        renderTopToolbar={({ table }) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "16px",
              padding: "8px",
              flexWrap: "wrap",
            }}
          >
            <Button
              onClick={() => { console.log(filteredUsersList); handleExportData(csvConfig, filteredUsersList.map(user => ({ ...user, groups: `${user.groups}` }))); }}
              startIcon={<FileDownloadIcon />}
            >
              Export All Data
            </Button>
            <Button
              onClick={() => {
                setDialogOpen(true);
                console.log(table.getSelectedRowModel().flatRows);
                setSelectedRows(table.getSelectedRowModel().flatRows.map(row => row.original));
              }}
              startIcon={<FileDownloadIcon />}
              disabled={!table.getIsSomeRowsSelected()}
            >
              Activate
            </Button>
          </Box>
        )}
      />
      <ActivationDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        users={selectedRows}
        refreshUsersFunction={refreshUsers}
      />
    </>
  );
}
