import React, { useMemo } from "react";
import { DeleteForever as DeleteButtonIcon, Edit } from "@mui/icons-material";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import TableLoadingOverlay from "../../components/MaterialTableLoadingOverlay";
import MaterialTable from "../../components/Table";
import { fetcher } from "../../utils/swr";

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
  },
}));

export default function Userlist({ pagination, setPagination, clientMnemonic }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const memoizedLoadingOverlay = useMemo(() => <TableLoadingOverlay />, []);

  const { data: companyUsers, error: companyUsersError } = useSWR(
    `/lms/api/v1/discovery/misight-users/${clientMnemonic}?limit=${pagination.pageSize}&page_idx=${pagination.pageIndex}`,
    fetcher,
  );

  const handlePaginationChange = (updater) => {
    const newPage = updater(pagination);
    setPagination(newPage);
  };

  const handleAddUser = () => {
    navigate("/accounts");
  };

  const columns = [
    { header: "First Name", accessorKey: "okta_first_name" },
    { header: "Last Name", accessorKey: "okta_last_name" },
    { header: "Email", accessorKey: "username" },
    { header: "Role", accessorKey: "okta_role" },
    { header: "Created At", accessorKey: "created_at", type: "datetime" },
    {
      header: "Actions",
      accessorKey: "actions",
      render: (row) => (
        <Button
          aria-label="edit"
          color="primary"
          onClick={() => window.open(`/user/${row.username}`)}
        >
          <Tooltip title="Edit" arrow>
            <Edit />
          </Tooltip>
        </Button>
      ),
    },
  ];

  return (

    <div style={{ padding: 20 }}>

      <MaterialTable
        columns={columns}
        data={companyUsers?.users || []}
        title="Users"
        manualPagination
        state={{
          pagination,
          isLoading: !companyUsers && !companyUsersError,
        }}
        rowCount={companyUsers?.userCount || 0}
        onPaginationChange={handlePaginationChange}
        components={{
          OverlayLoading: () => memoizedLoadingOverlay,
        }}
        renderTopToolbarCustomActions={() => (
          <Button
            variant="contained"
            onClick={() => { handleAddUser(); }}
          >
            Add User
          </Button>
        )}
      />
    </div>
  );
}
