export const roles = [
  // LMS
  { value: "Internal Wholesaler", territoriesKey: "wholesalers", userType: "LMS" },
  { value: "Field Wholesaler", territoriesKey: "wholesalers", userType: "LMS" },
  { value: "Divisional Manager", territoriesKey: "divisions", userType: "LMS" },
  { value: "National Account Manager", territoriesKey: "kam", userType: "LMS" },
  { value: "Business Intelligence", territoriesKey: "kam", userType: "LMS" },
  { value: "National Sales Manager", territoriesKey: "nsm", userType: "LMS" },
  // Discovery
  { value: "Academic", userType: "Discovery" },
  { value: "Admin (PR/Marketing/Billing/Procurement/IT/Finance)", userType: "Discovery" },
  { value: "BD Branch Manager", userType: "Discovery" },
  { value: "Business Development/Sales", userType: "Discovery" },
  { value: "Business Intelligence/Data Science", userType: "Discovery" },
  { value: "Business Leadership", userType: "Discovery" },
  { value: "Consultant", userType: "Discovery" },
  { value: "Corporate Actions/Securities Class Actions Operations", userType: "Discovery" },
  { value: "Corporate Treasury/Financial Control", userType: "Discovery" },
  { value: "CRM Manager", userType: "Discovery" },
  { value: "Data Analyst", userType: "Discovery" },
  { value: "Data Manager", userType: "Discovery" },
  { value: "ESG/Sustainability/Responsible Investment", userType: "Discovery" },
  { value: "HR", userType: "Discovery" },
  { value: "Insurance Agency Owner", userType: "Discovery" },
  { value: "Legal/Compliance/Risk Management", userType: "Discovery" },
  { value: "Marketing", userType: "Discovery" },
  { value: "Owner", userType: "Discovery" },
  { value: "Portfolio Manager/Portfolio Analyst/Trader", userType: "Discovery" },
  { value: "Product Management/Development", userType: "Discovery" },
  { value: "Proxy Voting/Corporate Governance Professional", userType: "Discovery" },
  { value: "Recruiter", userType: "Discovery" },
  { value: "Research", userType: "Discovery" },
  { value: "Unknown/Other", userType: "Discovery" },
  { value: "Wholesaler - External", userType: "Discovery" },
  { value: "Wholesaler - Internal", userType: "Discovery" },
];
