import React, { useCallback, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
  Chip,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import FormActions from "../../components/FormActions";
import FormAlerts from "../../components/FormAlerts";
import { API } from "../../utils/api";
import CompanyPrimaryInfo, {
  CompanyPrimaryInfoSchema,
} from "./CompanyPrimaryInfo";

export const Schema = Yup.object().shape({
  ...CompanyPrimaryInfoSchema,
});

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.primary.dark,
  },
  disabledTag: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
  enabledTag: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
}));

export default function CompanyProfileCard({
  displayName,
  mnemonic,
  industry,
  type,
  clientSuccessManager,
  abbreviation,
  subscriptionStartDate,
  subscriptionEndDate,
  disabledDate,
  excludeDNCRegistered,
  isSubscriptionEnabled,
  mutate,
  setRefreshSubscriptions,
}) {
  const { clientMnemonic } = useParams();
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [editing, setEditing] = useState(false);

  const classes = useStyles();

  const handleClose = useCallback(() => {
    setError(null);
    setSuccess(null);
    setEditing(false);
  }, []);

  const handleSave = useCallback(
    (companyProfile) => {
      setSaving(true);
      setError(null);
      setSuccess(null);
      return API.request({
        url: `/lms/api/v1/discovery/client/${clientMnemonic}`,
        method: "PUT",
        data: companyProfile,
      }).then(
        () => {
          setSuccess("-- Profile Successfully edited! --");
          setSaving(false);
          mutate();
          setRefreshSubscriptions(true);
        },
        (e) => {
          setError(JSON.stringify(e.response.data));
          setSaving(false);
        },
      );
    },
    [clientMnemonic, mutate, setRefreshSubscriptions],
  );

  return (
    <Formik
      initialValues={{
        display_name: displayName,
        mnemonic,
        industry: industry ?? "",
        abbreviation,
        type: type ?? "",
        client_success_manager: clientSuccessManager ?? "",
        subscription_start_date: new Date(
          subscriptionStartDate,
        ).toLocaleDateString("en-ca"),
        subscription_end_date: new Date(
          subscriptionEndDate,
        ).toLocaleDateString("en-ca"),
        disabled_date: disabledDate
          ? new Date(disabledDate).toLocaleDateString("en-ca")
          : new Date().toLocaleDateString("en-ca"),
        exclude_dnc_registered: excludeDNCRegistered,
        is_subscription_enabled: isSubscriptionEnabled,
      }}
      validationSchema={Schema}
      enableReinitialize
    >
      {(formik) => (
        <Card>
          {mnemonic || disabledDate ? (
            <CardHeader
              title={(
                <>
                  {`${mnemonic?.toUpperCase()}`}
                  {!isSubscriptionEnabled ? (
                    <Chip
                      label="Disabled"
                      className={classes.disabledTag}
                      size="small"
                    />
                  ) : (
                    <Chip
                      label="Enabled"
                      className={classes.enabledTag}
                      size="small"
                    />
                  )}
                </>
              )}
              subheader={(
                <>
                  {disabledDate != null && (
                    <div>{`Company Disabled at ${disabledDate}`}</div>)}
                  <div>{`Subscription Start Date ${subscriptionStartDate}`}</div>
                  <div>{`Subscription End Date ${subscriptionEndDate}`}</div>
                </>
              )}
            />
          ) : (
            <Skeleton style={{ width: "30%", margin: "0.5em" }} />
          )}
          <Divider />
          <CardContent>
            <CompanyPrimaryInfo
              disabled={!editing}
              switchStatus={
                disabledDate === null && isSubscriptionEnabled === false
              }
            />
          </CardContent>
          <Divider />
          <FormAlerts
            saving={saving}
            success={success}
            error={error}
            setSuccess={setSuccess}
            setError={setError}
          />
          {success ? (
            <Button onClick={handleClose} className={classes.cancelButton}>
              Close
            </Button>
          ) : (
            <FormActions
              editing={editing}
              setEditing={setEditing}
              handleCancel={() => formik.resetForm()}
              handleSave={() => handleSave(formik.values)}
              isValid={
                formik.dirty
                && (!formik.errors || Object.keys(formik.errors).length === 0)
              }
            />
          )}
        </Card>
      )}
    </Formik>
  );
}
