import React, { useState, useEffect } from "react";
import {
  Divider,
  Grid,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import useSWR, { useSWRConfig } from "swr";
import { fetcher, poster, deleter } from "../../../utils/swr";
import ClientSelect from "../ClientSelect";
import TransferList from "./TransferList";

export default function ClientBundleAssignment({ bundles }) {
  const { mutate } = useSWRConfig();
  const location = useLocation();

  // --- INTERNAL STATE ---
  const [client, setClient] = useState(location.state?.client);
  const [, setSuccess] = useState("");

  const { data: bundleAssignments } = useSWR(
    "lms/api/v1/misight/accessbundlesassignments",
    fetcher,
  );

  useEffect(() => {
    // Need to reset location.state on refresh
    window.history.replaceState({}, "");
  }, []);

  const assignedBundles = bundleAssignments?.filter(a => a.client_mnemonic === client) || [];
  const assignedBundlesEnriched = assignedBundles.map(assignedBundle => ({ ...bundles.find(b => b.id === assignedBundle.bundle_id), assignment_id: assignedBundle.id, assignment_updated_at: assignedBundle.updated_at, assignment_updated_by: assignedBundle.updated_by }));

  async function saveBundleAssignment(clientMnemonic, bundleId) {
    mutate(
      "lms/api/v1/misight/accessbundlesassignments",
      async () => {
        const updatedList = await poster(
          "lms/api/v1/misight/accessbundlesassignments",
          {
            client_mnemonic: clientMnemonic,
            bundle_id: bundleId,
          },
        );
        setSuccess("Update successfull");
        return [updatedList];
      },
      { revalidate: true },
    );
  }

  async function deleteBundleAssignment(id) {
    mutate(
      "lms/api/v1/misight/accessbundlesassignments",
      async () => {
        const updatedList = await deleter(
          `lms/api/v1/misight/accessbundlesassignments/${id}`,
        );
        setSuccess("Bundle Assignment Successfully Deleted");
        return [updatedList];
      },
      { revalidate: true },
    );
  }

  return (
    <Grid item xs={12}>
      <Grid item xs={12} style={{ display: "flex", flexWrap: "wrap" }}>
        <ClientSelect client={client} setClient={setClient} />
      </Grid>
      <Divider style={{ marginBlock: 30 }} />
      {client && (
        <TransferList
          assignedBundles={assignedBundlesEnriched}
          availableBundles={bundles?.filter(b => !assignedBundlesEnriched?.map(a => a.id)?.includes(b.id))}
          client={client}
          assignBundle={saveBundleAssignment}
          unassignBundle={deleteBundleAssignment}
        />
      )}
    </Grid>
  );
}
