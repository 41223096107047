import React, { useEffect, useMemo, useState } from "react";
import {
  DeleteForever as DeleteButtonIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import TableLoadingOverlay from "../../components/MaterialTableLoadingOverlay";
import MaterialTable from "../../components/Table";
import { fetchingLMS, fetchingLMSDone, setOptions } from "../../state";
import { deleteCompanyProfile, API } from "../../utils/api";
import { fetcher } from "../../utils/swr";
import CreateCompany from "./CreateCompany";

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
  },
}));

export default function Companies() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const dispatch = useDispatch();

  const { data: companyProfiles, error: companyProfilesError, mutate } = useSWR(
    "/lms/api/v1/discovery/client",
    fetcher,
  );

  const handleDeleteClick = (company) => {
    setSelectedCompany(company);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedCompany(null);
  };

  const handleConfirmDelete = async () => {
    if (!selectedCompany) return;

    setLoading(true);
    setDialogOpen(false);
    try {
      await deleteCompanyProfile(selectedCompany.mnemonic);
      mutate();
    } catch (error) {
      console.error("Error deleting company profile:", error);
    } finally {
      setLoading(false);
      dispatch(fetchingLMS({ element: "clients" }));
      API.request({ url: "/lms/api/v1/client/client", method: "GET" }).then((response) => {
        dispatch(setOptions({ option: "lms_backend_clients", value: response.data }));
        dispatch(fetchingLMSDone({ element: "clients" }));
      }).catch((error) => { console.log(error); });
    }
  };
  const memoizedLoadingOverlay = useMemo(() => <TableLoadingOverlay />, []);

  const columns = useMemo(
    () => [
      { header: "Name", accessorKey: "display_name" },
      { header: "Mnemonic", accessorKey: "mnemonic" },
      { header: "Industry", accessorKey: "industry" },
      { header: "Type", accessorKey: "type" },
      { header: "Client Success Manager", accessorKey: "client_success_manager" },
      { header: "Created at", accessorKey: "created_at" },
      {
        header: "Is Active",
        accessorKey: "is_active",
        Cell: ({ cell }) => (cell.row.original.is_active ? "Yes" : "No"),
      },
      {
        header: "No. of Users",
        accessorKey: "number_of_users",
      },
      {
        header: "Subscription Enabled",
        accessorKey: "is_subscription_enabled",
        Cell: ({ row }) => (row.original.is_subscription_enabled ? "Yes" : "No"),
      },
      {
        header: "Actions",
        accessorKey: "actions",
        Cell: ({ row }) => (
          <ButtonGroup variant="outlined">
            <Button
              aria-label="view"
              color="primary"
              onClick={() => navigate(`/company-profile/details/${row.original.mnemonic}`)}
            >
              <Tooltip title="View" arrow>
                <EditIcon />
              </Tooltip>
            </Button>
            <Button
              aria-label="delete"
              className={classes.deleteButton}
              onClick={() => handleDeleteClick(row.original)}
            >
              <Tooltip title="Delete" arrow>
                <DeleteButtonIcon />
              </Tooltip>
            </Button>
          </ButtonGroup>
        ),
      },
    ],
    [navigate, classes.deleteButton],
  );

  if (companyProfilesError) {
    return <div>Error loading company profiles...</div>;
  }

  return (
    <div>
      <CreateCompany mutateCompanies={mutate} />
      <MaterialTable
        isLoading={!companyProfiles || loading}
        columns={columns}
        data={companyProfiles ?? []}
        title="Companies"
        options={{
          pageSize: 20,
          pageSizeOptions: [20],
          headerStyle: {
            backgroundColor: "#30455c",
            color: "#97a9bc",
          },
        }}
        components={{
          OverlayLoading: memoizedLoadingOverlay,
        }}
      />
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="confirm-delete-title"
        aria-describedby="confirm-delete-description"
      >
        <DialogTitle id="confirm-delete-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-delete-description">
            Are you sure you want to delete the company &quot;
            {selectedCompany?.display_name}
            &quot;?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
