import React, { useContext, useState, useMemo, useEffect } from "react";
import {
  DeleteForever as DeleteButtonIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import TableLoadingOverlay from "../../components/MaterialTableLoadingOverlay";
import MaterialTable from "../../components/Table";
import { fetcher } from "../../utils/swr";
import { CompanyProfileContext } from "./CompanyProfileContext";

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
  },
}));

export default function Subscriptions({
  refreshSubscriptions,
  setRefreshSubscriptions,
}) {
  const { clientMnemonic } = useContext(CompanyProfileContext);
  const navigate = useNavigate();
  const [displayedRows, setDisplayedRows] = useState([]);
  const classes = useStyles();

  const {
    data: companySubscriptions,
    error: subscriptionsError,
    mutate: subscriptionsMutate,
  } = useSWR(
    `lms/api/v1/misight/accessbundleassignments/${clientMnemonic}`,
    fetcher,
  );

  const memoizedLoadingOverlay = useMemo(() => <TableLoadingOverlay />, []);

  useEffect(() => {
    if (companySubscriptions) {
      setDisplayedRows(companySubscriptions);
    }
  }, [companySubscriptions]);

  useEffect(() => {
    if (refreshSubscriptions) {
      subscriptionsMutate().then(() => {
        setDisplayedRows(companySubscriptions);
        setRefreshSubscriptions(false);
      });
    }
  }, [
    refreshSubscriptions,
    subscriptionsMutate,
    companySubscriptions,
    setRefreshSubscriptions,
  ]);

  const handleDelete = () => {
    navigate("/api-access-control", {
      state: { selectedTab: "clients", client: clientMnemonic },
    });
  };

  const handleSubscriptionInfoClick = (rowData) => {
    navigate("/api-access-control", {
      state: { selectedTab: "bundles", selectedBundle: rowData.name },
    });
  };

  const subscriptionColumns = [
    { header: "Subscription Name", accessorKey: "name" },
    { header: "Company Mnemonic", accessorKey: "client_mnemonic" },
    { header: "Created At", accessorKey: "created_at", type: "date" },
    {
      header: "Actions",
      accessorKey: "actions",
      render: (rowData) => (
        <ButtonGroup variant="outlined" className={classes.buttonGroup}>
          <Button
            aria-label="view"
            color="primary"
            onClick={() => handleSubscriptionInfoClick(rowData)}
          >
            <Tooltip title="View" arrow>
              <RemoveRedEyeIcon />
            </Tooltip>
          </Button>
          <Button
            aria-label="delete"
            className={classes.deleteButton}
            onClick={handleDelete}
          >
            <Tooltip title="Delete" arrow>
              <DeleteButtonIcon />
            </Tooltip>
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  if (subscriptionsError) {
    return (
      <Alert severity="error">{`Error fetching subscriptions: ${subscriptionsError.message}`}</Alert>
    );
  }

  return (
    <div style={{ padding: 20 }}>
      <MaterialTable
        isLoading={!companySubscriptions && !subscriptionsError}
        title="Subscriptions"
        columns={subscriptionColumns}
        data={displayedRows}
        components={{
          OverlayLoading: () => memoizedLoadingOverlay,
        }}
        renderTopToolbarCustomActions={() => (
          <Button
            variant="contained"
            onClick={() => {
              handleDelete();
            }}
          >
            Add Subscription
          </Button>
        )}
      />
    </div>
  );
}
