import React from "react";
import {
  Box,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { useFormikContext } from "formik";
import useSWR from "swr";
import * as Yup from "yup";
import LMSSwitch from "../../components/input/Formik/base/LMSSwitch";
import { fetcher } from "../../utils/swr";

export const CompanyPrimaryInfoSchema = {
  display_name: Yup.string(),
  mnemonic: Yup.string(),
  industry: Yup.string(),
  type: Yup.string(),
  client_success_manager: Yup.string(),
  abbreviation: Yup.string(),
  subscription_start_date: Yup.date(),
  subscription_end_date: Yup.date(),
  disabled_date: Yup.date(),
  exclude_dnc_registered: Yup.boolean(),
  is_subscription_enabled: Yup.boolean(),
};

export default function CompanyPrimaryInfo({ disabled, switchStatus }) {
  const { values, handleChange, handleBlur, touched, errors } = useFormikContext();
  const industries = ["Technology", "Service"];
  const types = ["Customer", "Partner"];
  const { data: managers, error, isLoading } = useSWR(
    "/users?group_name=client-success&pool=cognito_group",
    fetcher,
  );
  const commonFieldStyle = { width: "25%", marginRight: "5em" };

  const fieldsOptions = (
    <>
      <Box display="flex" justifyContent="space-around">
        {isLoading ? (
          <>
            <Skeleton style={commonFieldStyle} />
            <Skeleton style={commonFieldStyle} />
            <Skeleton style={commonFieldStyle} />
          </>
        ) : (
          <>
            <TextField
              select
              name="industry"
              value={values.industry}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.industry && Boolean(errors.industry)}
              label="Industry"
              style={commonFieldStyle}
              helperText={touched.industry && errors.industry}
            >
              {industries.map((industry) => (
                <MenuItem key={industry} value={industry}>
                  {industry}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              name="type"
              value={values.type}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.type && Boolean(errors.type)}
              label="Type"
              style={commonFieldStyle}
              helperText={touched.type && errors.type}
            >
              {types.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              name="client_success_manager"
              value={values.client_success_manager}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.client_success_manager
                && Boolean(errors.client_success_manager)
              }
              label="Client Success Manager"
              style={commonFieldStyle}
              helperText={
                touched.client_success_manager && errors.client_success_manager
              }
            >
              {managers ? (
                managers.users.map((manager) => (
                  <MenuItem key={manager.name} value={manager.name}>
                    {manager.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="none">none</MenuItem>
              )}
            </TextField>
          </>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" p={2}>
        <FormControlLabel
          control={(
            <Checkbox
              name="exclude_dnc_registered"
              checked={values.exclude_dnc_registered || false}
              onChange={handleChange}
              onBlur={handleBlur}
              color="primary"
            />
          )}
          label="Exclude DNC Registered"
        />

        {values.is_subscription_enabled !== undefined && (
          <LMSSwitch
            label={
              values.is_subscription_enabled
                ? "Disable Company"
                : "Re-enable Company"
            }
            name="is_subscription_enabled"
            disabled={switchStatus}
            checked={values.is_subscription_enabled}
            onChange={handleChange}
          />
        )}
      </Box>
    </>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div style={{ pointerEvents: disabled ? "none" : "all" }}>
        <Box display="flex" justifyContent="space-around">
          <TextField
            margin="normal"
            label="Name"
            name="display_name"
            value={values.display_name || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.display_name && Boolean(errors.display_name)}
            helperText={touched.display_name && errors.display_name}
            style={commonFieldStyle}
          />

          <TextField
            margin="normal"
            label="abbreviation"
            name="abbreviation"
            value={values.abbreviation || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.abbreviation && Boolean(errors.abbreviation)}
            helperText={touched.abbreviation && errors.abbreviation}
            style={commonFieldStyle}
          />

          <TextField
            margin="normal"
            label="Mnemonic"
            name="mnemonic"
            value={values.mnemonic || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.mnemonic && Boolean(errors.mnemonic)}
            helperText={touched.mnemonic && errors.mnemonic}
            style={commonFieldStyle}
            disabled
          />
        </Box>

        {fieldsOptions}
      </div>
    </LocalizationProvider>
  );
}
