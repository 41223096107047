import React, { useState, useMemo } from "react";
import { Button, Alert } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import useSWR from "swr";

import usePageTitle from "../../hooks/usePageTitle";
import { fetcher } from "../../utils/swr";
import CompanyProfileCard from "./CompanyProfileCard";
import { CompanyProfileContextProvider } from "./CompanyProfileContext";
import Subscriptions from "./Subscriptions";
import Userlist from "./Userlist";

export default function CompanyProfileDetails() {
  const { clientMnemonic } = useParams();
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    pageSize: 5,
    pageIndex: 0,
  });

  const [refreshSubscriptions, setRefreshSubscriptions] = useState(false);

  const { data: companyProfile, error: companyProfileError, mutate: primaryMutate } = useSWR(
    `/lms/api/v1/discovery/client/${clientMnemonic}`,
    fetcher,
  );

  usePageTitle("Company Profile Details");

  const loadingAlert = useMemo(() => !companyProfile && <Alert severity="info">Loading Company Profile Data...</Alert>, [companyProfile]);

  const errorAlert = useMemo(() => companyProfileError && (
    <Alert severity="error">{`Error getting Company Profile Data: ${companyProfileError}`}</Alert>
  ), [companyProfileError]);

  const subscriptionStartDate = useMemo(() => (companyProfile?.subscription_start_date
    ? new Date(companyProfile.subscription_start_date).toLocaleDateString("en-ca")
    : ""), [companyProfile]);

  const subscriptionEndDate = useMemo(() => (companyProfile?.subscription_end_date
    ? new Date(companyProfile.subscription_end_date).toLocaleDateString("en-ca")
    : ""), [companyProfile]);

  return (
    <CompanyProfileContextProvider
      clientMnemonic={companyProfile?.mnemonic}
      disabledDate={companyProfile?.disabled_date}
    >
      {loadingAlert}
      {errorAlert}
      <CompanyProfileCard
        displayName={companyProfile?.display_name}
        mnemonic={companyProfile?.mnemonic}
        industry={companyProfile?.industry}
        abbreviation={companyProfile?.abbreviation}
        type={companyProfile?.type}
        clientSuccessManager={companyProfile?.client_success_manager}
        subscriptionStartDate={subscriptionStartDate}
        subscriptionEndDate={subscriptionEndDate}
        disabledDate={companyProfile?.disabled_date}
        excludeDNCRegistered={companyProfile?.exclude_dnc_registered}
        isSubscriptionEnabled={companyProfile?.is_subscription_enabled}
        mutate={primaryMutate}
        setRefreshSubscriptions={setRefreshSubscriptions}
      />

      <Subscriptions
        refreshSubscriptions={refreshSubscriptions}
        setRefreshSubscriptions={setRefreshSubscriptions}
      />
      <Userlist
        pagination={pagination}
        setPagination={setPagination}
        clientMnemonic={clientMnemonic}
      />
      <Button variant="contained" color="primary" onClick={() => navigate("/discovery-data")}>
        Done
      </Button>
    </CompanyProfileContextProvider>
  );
}
