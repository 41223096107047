import React, { useState, useEffect, useRef } from "react";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Card, CardContent, Divider, Grid, Typography, CardHeader, Button, Box, Tooltip, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SimpleTreeView } from "@mui/x-tree-view";
import { createRow } from "material-react-table";
import ReactJson from "react-json-view";
import { useLocation } from "react-router-dom";
import { useSWRConfig } from "swr";

import ConfirmDialog from "../../../components/ConfirmDialog";
import FormAlerts from "../../../components/FormAlerts";
import Table from "../../../components/Table";
import usePageTitle from "../../../hooks/usePageTitle";
import { jsonSchema } from "../../../utils/graphqlDecoder";
import { poster, updater, deleter } from "../../../utils/swr";
import { GQlTreeItem } from "./components";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 14,
    color: theme.palette.textSecondary,
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  labelIconSelected: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  labelIconNotSelected: {
    marginRight: theme.spacing(1),
    color: theme.palette.warning.main,
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
  addIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  deleteIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
}));

function treeItems(fields, isSelected, setSelected, prefix, classes) {
  const fieldKey = (fieldName, pre) => (pre ? `${pre}.${fieldName}` : fieldName);

  return Object.keys(fields)?.map((fieldName) => (
    <GQlTreeItem
      fieldName={fieldName}
      fieldObj={fields[fieldName]}
      fieldKey={fieldKey(fieldName, prefix)}
      isSelected={isSelected}
      setSelected={setSelected}
      classes={classes}
      key={fieldName}
    >
      {fields[fieldName]?.fields && treeItems(fields[fieldName]?.fields, isSelected, setSelected, fieldKey(fieldName, prefix), classes)}
    </GQlTreeItem>
  ));
}

export default function Bundles({ bundles, error, isLoading }) {
  const { mutate } = useSWRConfig();
  const classes = useStyles();
  const location = useLocation();
  usePageTitle("API Access Control");

  // --- INTERNAL STATE ---
  const [rowSelection, setRowSelection] = useState({});
  const [selectedBundle, setSelectedBundle] = useState(location.state?.selectedBundle);
  // Selected fields state
  const [selected, setSelected] = useState([]);
  // Used to compare changes to selected fields before saving
  const [savedSelected, setSavedSelected] = useState([]);
  const [itemId, setItemId] = useState();
  const [fieldOptions, setFieldOptions] = useState([]);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [success, setSuccess] = useState("");

  const editBundleRef = useRef(null);
  useEffect(() => {
    // Need to reset location.state on refresh
    window.history.replaceState({}, "");
  }, []);

  async function saveBundle(title, fields) {
    setSuccess("");
    mutate(
      "lms/api/v1/misight/accessbundles",
      async () => {
        const updatedList = await poster(
          "lms/api/v1/misight/accessbundles",
          {
            name: title,
            fields: `${JSON.stringify(fields)}`,
          },
        );
        setSuccess("Update successfull");
        return [updatedList];
      },
      { revalidate: true },
    );
  }

  async function updateBundle(id, name) {
    setSuccess("");
    mutate(
      "lms/api/v1/misight/accessbundles",
      async () => {
        const updatedList = await updater(
          `lms/api/v1/misight/accessbundles/${id}`,
          {
            name,
            fields: `${JSON.stringify(selected)}`,
          },
        );
        setSuccess("Update successfull");
        return [updatedList];
      },
      { revalidate: true },
    );
  }

  async function deleteBundle(id) {
    setSuccess("");
    mutate(
      "lms/api/v1/misight/accessbundles",
      async () => {
        const updatedList = await deleter(
          `lms/api/v1/misight/accessbundles/${id}`,
        );
        setSuccess("Bundle Successfully Deleted");
        setRowSelection({});
        return [updatedList];
      },
      { revalidate: true },
    );
  }

  useEffect(() => {
    // Reset client vals
    setItemId(null);
    setSelected([]);
    // Apply saved data
    if (bundles) {
      const savedAccess = bundles?.find(d => d.name === rowSelection.name);
      if (savedAccess) {
        const fields = JSON.parse(savedAccess?.fields);
        setSelected(fields);
        setSavedSelected(fields);
        setItemId(savedAccess?.id);
      }
    }
  }, [bundles, rowSelection.name]);

  useEffect(() => {
    try {
      jsonSchema().then((result) => {
        setFieldOptions({ ...result?.queries?.fields, ...result?.mutations?.fields });
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const isSelected = (field) => selected?.some && selected?.some((v) => v === field);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4">
          List of bundles
        </Typography>
        <Divider style={{ marginBlock: 30 }} />
        <Table
          data={bundles || []}
          columns={[
            { header: "Bundle Name", accessorKey: "name" },
            { header: "Fields Added", accessorKey: "fields", Cell: ({ row }) => `${JSON.parse(row.original.fields).length} fields`, enableEditing: false, Edit: () => null },
            { header: "Updated", accessorKey: "updated_at", Cell: ({ row }) => new Date(row.original.updated_at).toDateString(), enableEditing: false, Edit: () => null },
            { header: "Updated By", accessorKey: "updated_by", enableEditing: false, Edit: () => null },
          ]}
          state={{ isLoading: isLoading || !bundles, rowSelection, editBundleRef }}
          muiSearchTextFieldProps={{
            placeholder: "Search Bundles",
            sx: { minWidth: "18rem" },
            variant: "outlined",
          }}
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => {
              setRowSelection((prev) => ({
                [row.id]: !prev[row.id], // Format that material-table uses for row selection
                name: row.original.name, // Format that's easier to access, not used by material-table
                id: row.original.id, // Format that's easier to access, not used by material-table
              }));
              editBundleRef?.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            },
            sx: { cursor: "pointer" },
            selected: rowSelection[row.id],
          })}
          enableEditing
          onCreatingRowSave={({ table, values }) => {
            saveBundle(values.name, values.fields || []);
            table.setCreatingRow(null);
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <Button
              onClick={() => {
                table.setCreatingRow(true);
              }}
            >
              Create a new bundle
            </Button>
          )}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip title="Clone Bundle">
                <IconButton
                  onClick={() => {
                    table.setCreatingRow(
                      createRow(
                        table,
                        {
                          fields: JSON.parse(row.original.fields),
                        },
                        -1,
                        row.depth + 1,
                      ),
                    );
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
        <FormAlerts
          success={success}
          error={error}
          setSuccess={setSuccess}
        />
      </Grid>
      {rowSelection?.name && (
        <>
          <Grid item xs={12} ref={editBundleRef}>
            <Typography variant="h4">
              Bundle:
              {" "}
              {rowSelection.name}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ minHeight: 300 }}>
            <Card>
              <CardHeader title="Key Management" style={{ textAlign: "center" }} />
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <CheckIcon className={classes.labelIconSelected} />
                <Typography variant="caption">Added</Typography>
                <ClearIcon className={classes.labelIconNotSelected} />
                <Typography variant="caption">Not added</Typography>
                <CheckCircleIcon className={classes.labelIconSelected} />
                <Typography variant="caption">All are added within</Typography>
                <ClearAllIcon />
                <Typography variant="caption">Add all in category</Typography>
              </div>
              <Divider style={{ marginBlock: 10 }} />
              <CardContent>
                <SimpleTreeView
                  className={classes.root}
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                >
                  {treeItems(fieldOptions, isSelected, setSelected, "", classes)}
                </SimpleTreeView>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} style={{ minHeight: 300 }}>
            <Card style={{ marginBottom: 10 }}>
              <CardHeader>Save</CardHeader>
              <CardContent style={{ display: "flex", justifyContent: "space-evenly" }}>
                <Button onClick={() => setSaveDialogOpen(true)}>Save</Button>
                <ConfirmDialog open={saveDialogOpen} setOpen={setSaveDialogOpen} confirmFunction={itemId !== null ? () => updateBundle(itemId, rowSelection.name) : () => saveBundle(rowSelection.name, selected)}>
                  <Typography variant="subtitle1">Adding:</Typography>
                  <Typography variant="caption">
                    Please confirm fields here should be accessed by
                    {" "}
                    <b>any</b>
                    {" "}
                    user or client tied to this bundle
                  </Typography>
                  {selected?.filter && selected?.filter(f => !savedSelected.includes(f)).map((field) => (
                    <Typography variant="overline" key={field} className={classes.labelRoot}>
                      <AddIcon className={classes.addIcon} />
                      <b>{field}</b>
                    </Typography>
                  ))}
                  <Typography variant="subtitle1">Removing:</Typography>
                  <Typography variant="caption">
                    Please confirm fields removed are not critical to
                    {" "}
                    <b>API</b>
                    {" "}
                    users or
                    {" "}
                    <b>MISIGHT</b>
                    {" "}
                    users tied to this bundle
                  </Typography>
                  {selected?.filter && savedSelected?.filter(f => !selected.includes(f)).map((field) => (
                    <Typography variant="overline" key={field} className={classes.labelRoot}>
                      <AddIcon className={classes.deleteIcon} />
                      <b>{field}</b>
                    </Typography>
                  ))}
                </ConfirmDialog>
                <Button onClick={() => setDeleteDialogOpen(true)}>Delete Bundle</Button>
                <ConfirmDialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen} confirmFunction={() => deleteBundle(itemId)} />
              </CardContent>
              <FormAlerts
                success={success}
                error={error}
                setSuccess={setSuccess}
              />
            </Card>
            <Card style={{ background: "#272822" }}>
              <CardContent>
                <p style={{ color: "white" }}>Raw Data</p>
                <ReactJson
                  src={{ fields: selected }}
                  name={false}
                  groupArraysAfterLength={1000}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  displayArrayKey={false}
                  shouldCollapse={(field) => field.type === "array" && field.src.length > 100}
                  theme="ocean"
                />
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
    </>
  );
}
